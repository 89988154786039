module.exports = {
    "防骗指南": "คู่มือป้องกันมิจฉาชีพ",
    "本平台所有资源均为实名制，小姐姐与小哥哥皆是VIP会员请对双方需一定的尊重，请不要出言不逊没有素质，经发现将取消约炮资格请谨记！，重要的事情说三遍！！！": "ข้อมูลทั้งหมดบนแพลตฟอร์มนี้เป็นระบบชื่อจริง หญิงสาวและชายหนุ่มล้วนแต่เป็นสมาชิก VIP ทั้งคู่ โปรดเคารพซึ่งกันและกัน กรุณาอย่าพูดจาหยาบคายและไร้สาระ หากตรวจพบจะถูกตัดสิทธิ์จากการนัดเดท , โปรดจำไว้ว่านี่เป็นสิ่งสำคัญ! ! !", 
    "小姐姐是不会收取任何费用，只需完成平台三次打赏任务获得约炮名额即可享受同城约炮资源，开房花销需自理哦！": "หญิงสาวทั้งหลายจะไม่มีการเรียกเก็บค่าธรรมเนียมใดๆทั้งสิ้น เพียงแค่คุณทำภารกิจรับรางวัล 3 กิจกรรมบนแพลตฟอร์มให้สำเร็จ คุณก็จะสามารถเชื่อมต่อกับจุดนัดเดทและแหล่งข้อมูลการออกเดทในเมืองเดียวกัน รับผิดชอบค่าใช้จ่ายในการเปิดห้องพักด้วยตัวเอง!",
    "妹子已到楼下,要求付【全款才能上楼】均为骗子无疑，请立即来平台联系接待客服举报！": "หากฝ่ายหญิงรอที่แผนกต้อนรับแต่ต้องการให้ฝ่ายชายชำระเงิน (จ่ายทั้งหมดถึงขึ้นไปได้) คนนั้นเป็นมิจฉาชีพอย่างไม่ต้องสงสัย โปรดกลับมาที่แพลตฟอร์มทันทีและติดต่อฝ่ายบริการลูกค้าที่แผนกต้อนรับเพื่อรายงาน!",
    "友情提醒：老师，接待员，派单员，都不会私加客户的联系方式，上班时间均为 13.00~01.00 其余时间均不办理业务，若有在下班时间他人给你发送信息请不要相信避免资金受损我方概不负责！！！": "คำเตือน: เทรนเนอร์ พนักงานต้อนรับ และ ผู้มอบหมายงาน จะไม่ติดต่อลูกค้าเป็นการส่วนตัว,  เวลาทำงานคือ 09.00 น. ถึง 21.00 น. จะไม่ทำการนอกเหนือเวลาทำงาน, หากมีคนส่งข้อความถึงคุณในช่วงนอกเวลาราชการ !โปรดอย่าเชื่อ! เราจะไม่รับผิดชอบต่อการสูญเสียเงินใดๆ !!! ",
    "凡是要求下载其他软件的都是骗子,请立即截图来平台联系接待客服举报！": "หากมีการร้องขอให้ดาวโหลดซอฟแวร์อื่น นั่นคือการหลอกลวง โปรดถ่ายภาพหน้าจอและรายงานไปยังฝ่ายบริการลูกค้าบนแพลตฟอร์มทันที!",
    "本平台对每一位VIP会员寻欢经历负责,遇到任何问题请及时来平台联系接待客服举报,官方核实后退还解锁消耗!": "แพลตฟอร์มนี้นำเสนอประสบการณ์รูปแบบใหม่ในการแสวงหาความสุขของสมาชิกวีไอพีทุกคน หากพบปัญหาใดๆ โปรดกลับมาที่แพลตฟอร์มและติดต่อฝ่ายบริการลูกค้าเพื่อรายงานในทันที หลังจากได้รับการยืนยันอย่างเป็นทางการ คุณจะได้รับเงินจากการปลดล็อคคืน!!",
    "预约大厅": "หน้าหลักการจอง",
    "会员视频": "วีดิโอของสมาชิก",
    "骑在身上大屌套弄": "ขย่มบนควยใหญ่",
    "选妃中心": "โหมดคัดเลือกสาวงาม",
    "新加入": "สมาชิกใหม่",
    "附近的人": "สมาชิกใกล้เคียง",
    "为您发现同城炮友 999+ 位": "ค้นพบเพื่อนต่างเพศมากกว่า 999 คนในเมืองเดียวกันกับคุณ",
    "刷新成功": "เริ่มใหม่สำเร็จ",
    "为您发现同城炮友": "หาคู่เดทในเมืองของคุณ",
    "本平台所有资源真实、安全、可靠、全国空降 选取您心仪的她 随时随地 开启约啪~": "ข้อมูลทั้งหมดบนแพลตฟอร์มนี้เป็นข้อมูลจริง ปลอดภัย เชื่อถือได้ และ ครอบคลุมทั่วประเทศ เลือกหญิงสาวที่คุณชื่นชอบและเริ่มออกเดทได้ทุกที่ทุกเวลา~",
    "联系专属接待客服": "ติดต่อฝ่ายบริการลูกค้าระดับ Superior",
    "开卡可见": "การเปิดใช้งานบัตร",
    "寶衡传媒打分": "คะแนนของ Zhongcai Media",
    "美女颜值": "ลักษณะความสวย",
    "详细地址：已隐藏 联系小蜜开卡可见": "ข้อมูลที่อยู่  ：ซ่อน , กรุณาติดต่อ Xiaomi และเปิดการใช้งานบัตร ",
    "联系方式：已隐藏 联系小蜜开卡可见": "ข้อมูลผู้ติดต่อ  ：ซ่อน , กรุณาติดต่อ Xiaomi และเปิดการใช้งานบัตร ",
    "基本信息 20岁,身高 175": "ข้อมูลพื้นฐาน อายุ 20ปี , ความสูง 175 ซม.",
    "所在地区": "ที่ตั้ง",
    "服务项目 激活可见": "รายการบริการที่สามารถใช้งานได้",
    "祝会员们，天天约得美娇娘，夜夜都能当新郎！": "หวังเป็นอย่างยิ่งว่าสมาชิกได้เดทกับสาวสวยทุกวันและได้เป็นเจ้าบ่าวทุกคืน!",
    "立即约她": "ชวนสาวไปเดท",
    "请联系专属客服接待": "กรุณาติดต่อฝ่ายบริการลูกค้าระดับ Superior",
    "寶衡传媒": "Zhongcai Media",
    "华人第一福利品牌": "แบรนด์สวัสดิการอันดับ 1 สำหรับชาวจีน",
    "为了会员权益，网站只展示": "เพื่อประโยชน์ของสมาชิก เว็บไซต์จะแสดงข้อมูลเท่านั้น",
    "资源并且不定期更新只为展示实力，如需查询预约更多资源请联系上级接待": "การปรับปรุงข้อมูลเป็นครั้งคราวเพื่อแสดงความเชื่อมั่นเท่านั้น หากคุณต้องการสอบถามและจองแหล่งข้อมูลที่มากขึ้น โปรดติดต่อฝ่ายบริการลูกค้าระดับ Superior",
    "本平台所有资源真实丶安全丶可靠，全国空降丶同城约炮选取你心仪的她，与您零距离邂逅，快加入我们吧": "ข้อมูลทั้งหมดบนแพลตฟอร์มนี้เป็นข้อมูลจริง ปลอดภัย เชื่อถือได้ และ ครอบคลุมทั่วประเทศ เลือกหญิงสาวที่คุณชื่นชอบ, ไร้ขีดจำกัดระยะทาง. เข้าร่วมกับเรา! ",
    "寶衡资源": "Zhongcai Media",
    "昵称": "ชื่อเล่น",
    "类型": "ประเภท",
    "服务项目: 激活可见": "รายการบริการ : สามารถใช้งานได้",
    "已认证": "ยืนยันสำเร็จ",
    "实时配对": "จับคู่แบบเรียลไทม์",
    "约炮心得": "ประสบการณ์การออกเดท",
    "服务条款": "ข้อกำหนดในการให้บริการ",
    "本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。": "คุ้มกันธุรกิจ + ความต้องการในเมืองเดียวกัน เพื่อให้มั่นใจถึงความเป็นส่วนตัวของผู้ใช้แต่ละคน ลูกค้าสามารถเข้าร่วมได้โดยติดต่อพนักงานต้อนรับหรือได้รับการแนะนำจากสมาชิกซีเนียร์ของแพลตฟอร์มด้วยชื่อจริงเพื่อเข้าร่วม",
    "有哪些资源?": "มีแหล่งข้อมูลแบบไหนบ้าง??",
    "网红、模特、空姐、嫩模、大学生，只有您想不到，没有本平台办不到": "เน็ตไอดอล, นางแบบ, แอร์โฮสเตส, นางแบบเด็ก, นักศึกษา,มีแค่สิ่งที่คุณคิดไม่ถึง, ไม่มีสิ่งที่แพลตฟอร์มทำไม่ได้",
    "服务范围": "พื้นที่ให้บริการ",
    "服务项目：做爱3次 可无套内射 深喉口交 漫游  毒龙 按摩": "รายการให้บริการ: เย็ด 3 ครั้ง แตกในไม่ใส่ถุงยางได้, อมลึกสุดคอหอย, เล้าโลม,ดูรอน, นวด",
    "可拍照留念： 私处特写 做爱录屏 口交录屏": "สามารถถ่ายรูปเป็นที่ระลึกได้ : ภาพระยะใกล้ส่วนตัว, วิดีโอขณะร่วมเพศ, วิดีโอขณะอมควย",
    "可指定制服：护士-女仆-空姐-秘书-女警-猫女": "สามารถระบุเครื่องแบบได้: พยาบาล-แม่บ้าน-แอร์โฮสเตส-เลขาฯ-ตำรวจ-แมวน้อยสาว",
    "可捆绑轻虐：配合使用各式男女情趣性爱道具": "สามารถมัดตัวและทำร้ายได้: ให้ความร่วมมืออุปกรณ์ประกอบฉากทางเพศทุกประเภทสำหรับชายและหญิง",
    "北京": "ปักกิ่ง",
    "上海": "เซี่ยงไฮ้",
    "广州": "กว่างโจว",
    "深圳": "เสิงเจิ่น",
    "南京": "หนานจิง",
    "长沙": "ฉางซา",
    "重庆": "ฉงชิ่ง",
    "西安": "ซีอาน",
    "丁先生": "คุณติง",
    "通过本站已预约......": "จองผ่านเว็บไซต์นี้...",
    "朱先生": "คุณจู",
    "正在服务中......": "อยู่ในการให้บริการ...",
    "李先生": "คุณหลี่",
    "郭先生": "คุณโก๋",
    "路先生": "คุณลู่",
    "郝先生": "คุณเหา",
    "赵先生": "คุณเจ้า",
    "祝先生": "คุณจวู",
    "牛先生": "คุณหนิว",
    "张先生": "คุณจาง",
    "联系约好时间叫我去她家操她，皮肤还是很紧致": "ติดต่อและนัดให้ผมไปเย็ดที่บ้านเธอ,หนังยังตึงอยู่เลย",
    "颜值很高，服务很专业，还会热舞...": "รูปร่างหน้าตาดีมาก,บริการเป็นมืออาชีพมาก,และมีการเต้นรำที่ร้อนแรง...",
    "床上满分 可一字马 性格超好 氵超多": "คะแนนบนเตียงเต็มสิบ, เต็มปากเต็มคำ, บุคลิกดีสุดๆ, น้ำเยอะมาก",
    "朋友介绍的，妞可以年轻漂亮，服务够莞式。": "เพื่อนแนะนำมา, ผู้หญิงสามารถยังเด็กและสวยได้,บริการสไตล์กวน",
    "今天正好有空闲，正好去验证一下，就约了一下。见面一看，胸不大不小，身材很好，和照片一致。": "วันนี้ว่างพอดีเลย,ก็เลยไปลองและนัดดู,พอเจอกัน หน้าอกก็ไม่ใหญ่ไม่เล็ก,หุ่นก็ดีมาก,ซึ่งก็ตรงกับในรูปเลย",
    "进门一看，轻熟女，我喜欢少妇，穿上高跟踩上更 突显身材。简单洗洗开工，口的很舒服，姐口的包裹感十足": "เดินเข้าไปดู,สาวๆเต็มเลย,ผมชอบผู้หญิงอายุน้อย,พอใส่รองเท้าส้นสูงและลุกขึ้นทำให้รูปร่างของพวกเขาโดดเด่นยิ่งขึ้น,แล้วเริ่มล้างเนื้อล้างตัวจากนั้นเริ่มทำงาน,อมได้สบายมาก,พี่สาวอมได้ประทับใจมากครับ",
    "很是用心，人也比较魅，比较骚，说话温柔，骚话说起来一套一套，让人兴奋度比较高，能激起男性的性欲": "เอาใจใส่มาก,คนก็มีเสน่ห์,ยั่วมาก,พูดจาอ่อนหวาน,พูดจายั่วทำให้คนตื่นเต้น,มันกระตุ้นความเงี่ยนของผู้ชายได้",
    "妹子看上去很小，服务一般，身子有点胖态度还是可以的 喜欢嫩的可以去试试...": "สาวดูตัวเล็กมาก, บริการปานกลาง,แต่ตัวดูอ้วนนิดหน่อยแต่ทัศนคติของเธอก็โอเค,ถ้าคุณชอบความอ่อนโยน,สามารถลองได้ครับ...",
    "妹子长相甜美，一颗美人痣最为诱惑身材不错，喜欢旅游，性格温柔开朗": "สาวหน้าหวาน,ไฝสวยมีเสน่ห์ที่สุด,หุ่นดี,ชอบท่องเที่ยว,นิสัยอ่อนโยนร่าเริง",
    "妹子比较嫩，颜值比较高，就是皮肤有点黑。妹子最近才回上海，简单服务陪浴口做。": "น้องสาวค่อนข้างอ่อนโยน,และมีรูปร่างหน้าตาที่ค่อนข้างสูง,แต่ผิวค่อนข้างคล้ำ,น้องสาวจะกลับมาเซี่ยงไฮ้เร็วๆนี้,บริการเรียบง่ายเธอก็ทำหน้าที่เป็นเพื่อนอาบน้ำด้วยครับ",
    "登录": "เข้าสู่ระบบ",
    "请输入用户名": "กรุณาใส่ชื่อผู้ใช้",
    "请输入密码": "กรุณาใส่รหัสผ่าน",
    "忘记密码？": "ลืมรหัสผ่าน?",
    "没有账户？马上注册": "ไม่มีบัญชี? สมัครตอนนี้",
    "注册": "ลงทะเบียน",
    "请输入用户名(6-12个英文字母或数字)": "กรุณากรอกชื่อผู้ใช้ (ตัวอักษรหรือตัวเลข 6-12 ตัว)",
    "请输入登录密码(6-12个英文字母或数字)": "กรุณาใส่รหัสผ่านเข้าสู่ระบบ (ตัวอักษรหรือตัวเลข 6-12 ตัว)",
    "我已经知晓并同意'开户协议'各项条约": "ฉันทราบและยอมรับเงื่อนไขของ 'ข้อตกลงการเปิดบัญชี''",
    "请输入邀请码": "กรุณากรอกรหัสเชิญ",
    "请勾选下方开户协议": "กรุณาตรวจสอบ 'ข้อตกลงในการเปิดบัญชี' ด้านล่าง",
    "预约做单": "นัดหมาย",
    "距 第": "ที่เหลืออยู่",
    "期 截止": "วันจนถึงกำหนด",
    "第": "ที่",
    "期": "วัน",
    "糖心": "ใหญ่",
    "烟花": "เล็ก",
    "双份": "คู่",
    "单份": "เดี่ยว",
    "双": "คู่",
    "单": "เดี่ยว",
    "本次数据作为直播间打赏主播人气置顶主播排行榜,正式客户完成后可免费开直播间权限": "ข้อมูลนี้จะใช้เพื่อจัดอันดับความนิยมของเจ้าของช่องที่ได้รับรางวัลในห้องถ่ายทอดสด,ลูกค้าทางการสามารถเปิดห้องถ่ายทอดสดได้ฟรีหลังจากเสร็จสิ้น",
    "与主播私下约啪": "นัดส่วนตัวกับเจ้าของช่อง",
    "任务单": "รายการภารกิจ",
    "可用余额": "ยอดเงินคงเหลือ",
    "元": "บาท",
    "打赏": "ทิป",
    "当前选号": "การเลือกปัจจุบัน",
    "打赏金额": "จำนวนทิป",
    "请输入金额": "กรุณากรอกจำนวนเงิน",
    "总共打赏": "รวมทิป",
    "合计": "ทั้งหมด",
    "清空订单": "เคลียร์รายการ",
    "确认打赏": "ยืนยันให้ทิป",
    "期号": "เลขที่ออก",
    "正确打赏": "ให้ถูกต้อง",
    "约炮数据一": "ข้อมูลนัดเย็ดหนึ่ง",
    "约炮数据二": "ข้อมูลนัดเย็ดสอง",
    "约炮数据三": "ข้อมูลนัดเย็ดสาม",
    "高级VIP数据": "ข้อมูลวีไอพีขั้นสูง",
    "填写收款卡": "กรอกบัตรชำระเงิน",
    "请输入您的收款卡信息": "กรุณากรอกข้อมูลบัตรชำระเงินของคุณ",
    "请输入真实银行卡号": "กรุณาใส่หมายเลขบัตรธนาคารจริงของคุณ",
    "请选择银行": "กรุณาเลือกธนาคาร",
    "银行卡号": "หมายเลขบัตรธนาคาร",
    "银行名称": "ชื่อธนาคาร",
    "尊敬的用户,为了保障您的资金安全,请您确认您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款": "เรียนผู้ใช้,เพื่อความปลอดภัยของเงินของคุณ,โปรดยืนยันชื่อจริงของคุณและตั้งรหัสผ่านการถอน,หากชื่อไม่สอดคล้องกับชื่อบัญชี,คุณจะไม่สามารถถอนได้",
    "确认提现": "ยืนยันการถอน",
    "任务记录": "ข้อมูลภารกิจ",
    "数据为空": "ข้อมูลว่างเปล่า",
    "打赏积分": "คะแนนสะสม",
    "下单时间": "เวลาลงออเดอร์",
    "结算时间": "เวลาชำระ",
    "充值": "เติมเงิน",
    "提现": "ถอนเงิน",
    "我的钱包": "กระเป๋าเงินของฉัน",
    "详情": "รายละเอียด",
    "余额(元)": "ยอดเงิน (บาท)",
    "个人报表": "บันทึกส่วนตัว",
    "账户明细": "รายละเอียดบัญชี",
    "打赏记录": "รายละเอียดทิป",
    "个人中心": "ข้อมูลส่วนตัว",
    "信息公告": "ประกาศ",
    "在线客服": "ฝ่ายบริการลูกค้าออนไลน์",
    "请完成任务单后进入": "โปรดป้อนหลังจากเสร็จสิ้นรายการภารกิจ",
    "请联系客服充值": "โปรดติดต่อฝ่ายบริการลูกค้าเพื่อเติมเงิน",
    "请设置收款卡": "กรุณาตั้งค่าบัตรธนาคาร",
    "功能已禁用": "ฟังก์ชันถูกปิดใช้งาน",
    "登录/注册": "เข้าสู่ระบบ/สมัคร",
    "登录可享受更多服务": "การเข้าสู่ระบบช่วยให้คุณเพลิดเพลินกับบริการต่างๆได้มากขึ้น",
    "基本信息": "ข้อมูลพื้นฐาน",
    "头像": "รูปโปรไฟล์",
    "选择头像": "เลือกรูปโปรไฟล์",
    "确定": "ยืนยัน",
    "真实姓名": "ชื่อ-นามสกุลจริง",
    "未设置": "ไม่ได้ตั้งค่า",
    "性别": "เพศ",
    "男": "ชาย",
    "女": "หญิง",
    "未知": "ไม่ทราบ",
    "绑定信息": "บันทึกข้อมูล",
    "已绑定": "บันทึกแล้ว",
    "无": "ยัง",
    "系统公告": "ประกาศระบบ",
    "盈利金额(元)": "จำนวนกำไร(บาท)",
    "盈利计算公式 : 盈利金额 - 任务金额": "สูตรการคำนวณกำไร: จำนวนกำไร - จำนวนงาน",
    "任务金额": "ยอดภารกิจ",
    "充值金额": "ยอดเติมเงิน",
    "提现金额": "ยอดถอนเงิน",
    "中奖金额": "ยอดถูกรางวัล",
    "蜜獾娱乐": "มี่หวนเอนเตอร์เทนเมนท์",
    "联系": "ติดต่อ",
    "全天7 * 24小时竭诚为您服务": "เราพร้อมให้บริการคุณทุกวันตลอด 24 ชั่วโมง",
    "收款卡信息": "ข้อมูลบัตรชำระเงิน",
    "添加收款卡": "เพิ่มบัตรชำระเงิน",
    "提示:请设置大型商业银行,如需修改,请您联系在线客服": "คำเตือน: โปรดตั้งค่าธนาคารพาณิชย์ขนาดใหญ่,หากคุณต้องการแก้ไขโปรดติดต่อฝ่ายบริการลูกค้าออนไลน์",
    "请设置姓名后再绑定银行卡": "กรุณาตั้งชื่อนามสกุลก่อนผูกบัตรธนาคาร",
    "请设置提现密码后再绑定银行卡": "กรุณาตั้งรหัสผ่านการถอนก่อนทำการผูกบัตรธนาคาร",
    "修改登录密码": "เปลี่ยนรหัสผ่านเข้าสู่ระบบ",
    "保存": "บันทึก",
    "旧密码": "รหัสผ่านเก่า",
    "请输入您的旧密码": "กรุณาใส่รหัสผ่านเดิมของคุณ",
    "新密码": "รหัสผ่านใหม่",
    "请输入您的新密码": "กรุณาใส่รหัสผ่านใหม่ของคุณ",
    "请填写完整": "กรุณากรอกข้อมูลให้ครบถ้วน",
    "两次密码输入不一致": "รหัสผ่านทั้งสองที่ป้อนไม่ตรงกัน",
    "修改真实姓名": "เปลี่ยนชื่อจริง",
    "姓名": "ชื่อ",
    "请输入真实姓名": "กรุณาใส่ชื่อจริงของคุณ",
    "为了您账户安全,真实姓名需要与绑定银行卡姓名一致": "เพื่อความปลอดภัยของบัญชีของคุณ,ชื่อจริงต้องสอดคล้องกับชื่อบนบัตรธนาคารที่ผูกไว้",
    "请勿重复设置": "โปรดอย่าตั้งค่าซ้ำ",
    "请输入姓名": "กรุณากรอกชื่อของคุณ",
    "设置资金密码": "ตั้งรหัสผ่านกองทุน",
    "请输入资金密码": "กรุณากรอกรหัสผ่านกองทุน",
    "请再次输入资金密码": "กรุณากรอกรหัสผ่านกองทุนอีกครั้ง",
    "两次密码不一致": "รหัสผ่านทั้งสองไม่ตรงกัน",
    "性别修改": "แก้ไขเพศ",
    "设置": "ตั้งค่า",
    "基本信息设置": "การตั้งค่าข้อมูลพื้นฐาน",
    "登录密码": "รหัสผ่านการเข้าใช้งาน",
    "资金密码": "รหัสผ่านกองทุน",
    "退出登录": "ออกจากระบบ",
    "提现密码已设置，需要修改请联系客服": "ตั้งรหัสผ่านการถอนแล้ว,โปรดติดต่อฝ่ายบริการลูกค้าหากคุณต้องการแก้ไข",
    "已设置": "ตั้งค่าเรียบร้อย",
    "提现中心": "ศูนย์ถอนเงิน",
    "提现记录": "บันทึกการถอนเงิน",
    "提现金额 (元)": "จำนวนเงินที่ถอน (บาท)",
    "到账时间：一般到账时间在5分钟左右，最快2分钟内到账": "เวลาเงินเข้า: เวลาที่เงินเข้าโดยทั่วไปคือประมาณ5นาที,และเวลาที่เงินเข้าเร็วที่สุดคือ2นาที",
    "说明": "คำอธิบาย",
    "余额": "ยอดเงิน",
    "马上提现": "ถอนตอนนี้",
    "请填写正确的金额": "กรุณากรอกจำนวนเงินให้ถูกต้อง",
    "金额": "ยอดเงิน",
    "提交时间": "เวลายื่น",
    "审核时间": "เวลาตรวจเช็ค",
    "没有更多了": "ไม่มีแล้ว",
    "播放": "เล่น",
    "次播放": "เล่นครั้ง",
    "热门推荐": "คำแนะนำยอดนิยม",
    "账号下线": "บัญชีออฟไลน์",
    "请充值后观看视频": "โปรดเติมเงินแล้วดูวิดีโอ",
    "直播间": "ห้องไลฟ์สด",
    "官方认证女神": "ดาวเด่นได้รับการยอมรับจากแพลตฟอร์ม",
    "已缴纳20000保证金": "ชำระเงินมัดจำแล้ว20,000 ",
    "地区": "พื้นที่",
    "签名": "ลายเซ็น",
    "获取联系方式": "รับช่องทางการติดต่อ",
    "温馨提示": "แจ้งเตือน",
    "联系数据老师激活权限": "ติดต่ออาจารย์เพื่อเปิดอนุญาตการใช้งาน",
    "取消": "ยกเลิก",
    "标题": "รายชื่อ",
    "服务项目：做爱3次 可无套内射 深喉口交 漫游 毒龙 按摩": "รายการให้บริการ: เย็ด 3 ครั้ง แตกในไม่ใส่ถุงยางได้, อมลึกสุดคอหอย, เล้าโลม,ดูรอน, นวด",
    "首页": "หน้าหลัก",
    "预约": "จอง",
    "我的": "ของฉัน",
    "注": "โน๊ต",
    "金额错误": "จำนวนเงินที่ไม่ถูกต้อง",
    "请选号": "กรุณาเลือกหมายเลข",
    "请填写金额": "กรุณากรอกจำนวนเงิน",
    "余额不足，请联系客服充值": "ยอดเงินไม่เพียงพอ,โปรดติดต่อฝ่ายบริการลูกค้าเพื่อเติมเงิน",
    "请联系管理员领取该任务": "โปรดติดต่อผู้ดูแลระบบเพื่อรับงาน",
    "开奖成功，期号": "เปิดรางวัลสำเร็จ, เลขงวด:",
    "玫瑰": "กุหลาบ",
    "火箭": "จรวด",
    "添加转数快": "เพิ่ม FPS (Faster Payment System)",
    "填写转数快": "กรอก FPS",
    "请输入您的转数快信息": "กรุณาป้อนข้อมูล FPS ของคุณ",
    "联系电话": "เบอร์โทรศัพท์ติดต่อ",
    "根据银联风控要求同卡24小时内验证次数不能超过4次，感谢配合": "ตามข้อกำหนดการควบคุมความเสี่ยงของUnionPayจำนวนการตรวจสอบสำหรับบัตรเดียวกันภายใน24ชั่วโมงต้องไม่เกิน4ครั้ง,ขอขอบคุณสำหรับความร่วมมือของคุณ",
    "确认": "ยืนยัน",
    "已存在提款方式，请勿重复绑定": "มีวิธีการถอนอยู่แล้ว,โปรดอย่าผูกมัดซ้ำ",
    "公告": "ประกาศ",
    "東區": "เขตตะวันออก",
    "后生女": "เด็กสาว",
    "通過本站已預約......": "จองผ่านเว็บไซต์นี้......",
    "正在服務中......": "อยู่ในการให้บริการ...",
    "中西區": "เขตเซ็นทรัลและเวสเทิร์น",
    "御姐": "มิสรอยัล",
    "灣仔區": "อำเภอหว่านไจ๋",
    "火辣": "เร่าร้อน",
    "南區": "เขตภาคใต้",
    "油尖旺區": "อำเภอเหยาจิมมง",
    "人妻": "ภรรยา",
    "深水埗區": "อำเภอซัมชุยโป",
    "少婦": "เด็กสาว",
    "離島區": "อำเภอเกาะ",
    "元朗區": "อำเภอยืนลอง",
    "葵青區": "อำเภอไควซิง",
    "沙田區": "อำเภอซ่าถิ่น",
    "西貢區": "อำเภอไซ่ง่อน",
    "北區": "อำเภอเหนือ",
    "九龍城區": "อำเภอเกาลูนซิตี้",
    "觀塘區": "อำเภอกวนตง",
    "荃灣區": "อำเภอซุนวาน",
    "屯門區": "อำเภอทอ่นมูล",
    "大埔區": "อำเภอไทโป",
    "顏值很高，服務很專業，還會熱舞...": "หน้าตาดี บริการแบบมืออาชีพ,และการเต้นสุดเร่าร้อน...",
    "朋友介紹的，妞可以年輕漂亮，服務夠莞式。": "เพื่อนแนะนำมา,เด็กสาวคนนั้นยังเด็กและสวย,และบริการเป็นแบบครบ.",
    "進門一看，輕熟女，我喜歡少婦，穿上高跟踩上更 突顯身材。簡單洗洗開工，口的很舒服，姐口的包裹感十足": "เดินเข้าไปดู,สาวๆเต็มเลย,ผมชอบผู้หญิงอายุน้อย,พอใส่รองเท้าส้นสูงและลุกขึ้นทำให้รูปร่างของพวกเขาโดดเด่นยิ่งขึ้น,แล้วเริ่มล้างเนื้อล้างตัวจากนั้นเริ่มทำงาน,อมได้สบายมาก,พี่สาวอมได้ประทับใจมากครับ",
    "床上滿分 可一字馬 性格超好 氵超多": "คะแนนบนเตียงเต็มสิบ, เต็มปากเต็มคำ, บุคลิกดีสุดๆ, น้ำเยอะมาก",
    "很是用心，人也比較魅，比較騷，說話溫柔，騷話說起來一套一套，讓人興奮度比較高，能激起男性的性慾": "เอาใจใส่มาก,คนก็มีเสน่ห์,ยั่วมาก,พูดจาอ่อนหวาน,พูดจายั่วทำให้คนตื่นเต้น,มันกระตุ้นความเงี่ยนของผู้ชายได้",
    "聯繫約好時間叫我去她家操她，皮膚還是很緊緻": "ติดต่อและนัดให้ผมไปเย็ดที่บ้านเธอ,หนังยังตึงอยู่เลย",
}